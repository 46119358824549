import request from "./axios";
const moment = require('moment')

// 获取地址接口
function getAddress(data) {
  return request({
    url: "/Api/getAddress",
    method: 'post',
    data
  });
}

//获取图片接口
function getImages(data) {
  return request({
    url: "/Api/getImages",
    method: 'post',
    data
  });
}
//获取商铺接口
function getStores(data) {
  return request({
    url: "/Api/getStores",
    method: 'post',
    data
  });
}

function getHeatingPower() {
  return sendRequest("api.electronicfence.info")
}

// 每小时人流量
function getHeatingPowerHourly() {
  let today = moment().format("YYYY-MM-DD")
  return sendRequest('api.electronicfence.hourlyinfo', {
    "end_date": today + " 24:00:00",
    "org_id": 565,
    "start_date": today + " 00:00:00",
  })
}

// 每天人流量
function getHeatingPowerDaily() {
  let start = moment().subtract(7, 'days').format("YYYY-MM-DD 00:00:00")
  let end = moment().subtract(1, 'days').format("YYYY-MM-DD 23:59:59")
  return sendRequest('api.electronicfence.dailyinfo', {
    "end_date": end,
    "org_id": 565,
    "start_date": start,
  })
}

function getPointPassengers() {
  return sendRequest('api.userflow.realTime', {
    "org_id": 565
  })
}

// 天气
function getWeather() {
  return sendRequest('api.weather')
}

// 消防
function getFireproofDevices(type, rows, page) {
  return request({
    method: 'post',
    url: "/business-bi-server/hikvision/fireproof_device/list",
    params: {
      device_type: type,
      rows: rows,
      page: page
    },
    data: {}
  })
}
function getfireproof_alarm(state, rows, page) {
  return request({
    method: 'post',
    url: "business-bi-server/hikvision/fireproof_alarm/list",
    params: {
      process_state: state,
      rows: rows,
      page: page
    },
    data: {}
  })
}

// 摄像头
function getCameras() {
  return sendRequest('api.camera.info')
}

function getCameraStreamURL(site_code) {
  return request({
    method: 'get',
    url: "/camera/preview_url",
    params: {
      site_code: site_code
    }
  })
}

function getMaintains() {
  return request({
    method: 'post',
    url: "/business-bi-server/hikvision/fireproof_maintenance/list",
    data: {}
  })
}

// 商铺
// -- 销售排行
function getStoreTurnover() {
  let today = moment().format("YYYY-MM-DD 23:59:59")
  let yesterday = moment().subtract(1, 'days').format("YYYY-MM-DD 00:00:00")
  return sendRequest('api.userflow.order', {
    "end_time": today,
    "group_type": "org",
    "org_id": 565,
    "start_time": yesterday
  })
}

function getCouponUsage() {
  const start = moment().clone().startOf('month').format('YYYY-MM-DD 00:00:00');
  const end = moment().clone().endOf('month').format('YYYY-MM-DD 23:59:59');
  return sendRequest('api.member.voucher', {
    "end_time": end,
    "shopper_id": "6552038",
    "start_time": start,
  })
}

function getMemberInfo() {
  let today = moment().format("YYYY-MM-DD 00:00:00")
  let yesterday = moment().subtract(1, 'days').format("YYYY-MM-DD 00:00:00")
  return sendRequest('api.member.info', {
    "shopper_id": 6552038,
    "today_date": today,
    "yesterday_date": yesterday
  })
}

function getIndestryInfo() {
  return sendRequest("api.shop.percent")
}

function getCouponStat() {
  let end = moment().add(1, 'days').format("YYYY-MM-DD 00:00:00")
  let start = moment().subtract(6, 'days').format("YYYY-MM-DD 00:00:00")
  return sendRequest("api.member.findVoucherStatisticsAndRate", {
    "end_date": end,
    "shop_id": "6552038",
    "start_date": start,
  })
}

// 商圈
function getNotices() {
  return request({
    method: 'get',
    url: "/data-site/business-data-site-offer/app/notice/list?orgId=ddd3362bf004423094336a52b0198abv&storeId=6552039&shopId=6552038",
    headers: {
      "invoke_source": "9999"
    }
  })
}

function getBanners() {
  return request({
    method: 'get',
    url: "data-site/business-data-site-offer/app/advertisement/list?orgId=ddd3362bf004423094336a52b0198abv&storeId=6552039&shopId=6552038&position=1",
    headers: {
      "invoke_source": "9999"
    }
  })
}

function getActivities() {
  return request({
    method: 'post',
    url: "/data-site/business-data-site-offer/app/content/list",
    data: {
      "shop_id": 6552038,
      "type_primary_id": "zxhd"
    },
    headers: {
      "invoke_source": "9999"
    }
  })
}

function getCoupons() {
  return request({
    method: 'post',
    url: "/member-user/voucher/queryUserAvailableCoupons",
    data: {
      "shop_id": 6552038,
      "page_num": 1,
      "page_size": 20
    },
    headers: {
      "invoke_source": "9999"
    }
  })
}

// 商铺管理
function getTrafficTrend() {
  let start = moment().subtract(10, 'days').format("YYYY-MM-DD 00:00:00")
  let end = moment().format("YYYY-MM-DD 00:00:00")
  return sendRequest('api.userflow.into', {
    "end_time": end,
    "org_id": 565,
    "start_time": start,
  })
}

function getTrafficTrendAll() {
  let start = moment().subtract(10, 'days').format("YYYY-MM-DD")
  let end = moment().subtract(1, 'days').format("YYYY-MM-DD")
  return sendRequest('api.userflow.group', {
    "end_time": end,
    "group_type": "org",
    "org_id": 565,
    "start_time": start,
  })
}

// accident
function getAccidentsByType() {
  return sendRequest('api.accident.type', {
    "shop_id": 6552038,
  })
}

function getAccidentsByStatus() {
  return sendRequest('api.accident.status', {
    "shop_id": 6552038,
  })
}

// complaints
function getComplaintInfo() {
  let start = moment().subtract(10, 'days').format("YYYY-MM-DD 00:00:00")
  let end = moment().subtract(1, 'days').format("YYYY-MM-DD 23:59:59")
  return sendRequest('api.opinion.analysis', {
    "end_time": end,
    "org_id": 565,
    "start_time": start,
  })
}

function getUnhandledComplaintList() {
  let start = moment().subtract(10, 'days').format("YYYY-MM-DD 00:00:00")
  let end = moment().format("YYYY-MM-DD 23:59:59")
  return sendRequest('api.opinion.list', {
    "end_time": end,
    "org_id": 565,
    "start_time": start,
  })
}

function getStoreRanking() {
  const start = moment().clone().startOf('month').format('YYYY-MM-DD 00:00:00');
  const end = moment().clone().endOf('month').format('YYYY-MM-DD 23:59:59');
  return sendRequest('api.order.evaluation', {
    "end_time": end,
    "org_id": 565,
    "start_time": start,
  })
}
function getOrders(start, end) {
  start ||= moment().format("YYYY-MM-DD 00:00:00")
  end ||= moment().format("YYYY-MM-DD 23:59:59")
  return sendRequest('api.order.summary', {
    "end_time": end,
    "org_id": 565,
    "start_time": start,
  })
}
function getCouponStatDaily() {
  let start = moment().subtract(7, 'days').format("YYYY-MM-DD 00:00:00")
  let end = moment().subtract(1, 'days').format("YYYY-MM-DD 23:59:59")
  return sendRequest('api.member.getVoucherStatistics', {
    "end_date": end,
    "shop_id": 6552038,
    "start_date": start,
  })
}

function getMemberLayers() {
  // const start = moment().clone().startOf('month').format('YYYY-MM-DD 00:00:00');
  const start = ('2021-03-01 00:00:00');
  const end = moment().clone().endOf('month').format('YYYY-MM-DD 23:59:59');
  return request({
    method: 'post',
    url: "/marketing/street/queryMemberLayered",
    data: {
      "end_date": end,
      "service_id": 6552049,
      "shopper_id": 6552038,
      "start_date": start
    },
    headers: { "invoke_source": "9999", "content-type": "application/json" }
  })
}

function getScenePassengers() {
  return request({
    method: 'get',
    url: "/api_proxy/scenic_points/traffic",
  })
}

function getMPMemberInfo() {
  const start = moment().subtract(7, 'days').format('YYYY-MM-DD 00:00:00');
  const end = moment().subtract(1, 'days').format('YYYY-MM-DD 23:59:59');
  return sendRequest('api.member.userAnalysis', {
    "begin_date": start,
    "end_date": end,
    "shopper_id": 6552038,
  })
}

function sendRequest(method, data = { "org_id": 565 }) {
  return request({
    method: 'post',
    url: "/business-data-center-offer/api/gateway.htm",
    data: data,
    headers: { "method": method }
  })
}

export {
  getAddress,
  getImages,
  getStores,
  getHeatingPower,
  getHeatingPowerHourly,
  getHeatingPowerDaily,
  getWeather,
  getFireproofDevices,
  getStoreTurnover,
  getNotices,
  getBanners,
  getActivities,
  getCoupons,
  getCameras,
  getCameraStreamURL,
  getTrafficTrend,
  getTrafficTrendAll,
  getCouponUsage,
  getMemberInfo,
  getMaintains,
  getIndestryInfo,
  getUnhandledComplaintList,
  getCouponStat,
  getfireproof_alarm,
  getPointPassengers,
  getAccidentsByType,
  getAccidentsByStatus,
  getComplaintInfo,
  getStoreRanking,
  getOrders,
  getCouponStatDaily,
  getMemberLayers,
  getMPMemberInfo,
  getScenePassengers
}
